import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";

import Link from "../link";

const ButtonStyle = styled.button`
    ${tw`text-white font-sans border-primary-light rounded-full text-md px-5 py-3 border-2 md:text-lg md:px-6 md:py-4 hover:bg-primary-light hover:text-primary-dark light-mode:bg-transparent light-mode:text-primary light-mode:border-primary light-mode:hover:bg-primary light-mode:hover:text-white`}
    transition: all 0.3s ease-in-out;
`;

const Button = ({ children, target, to }) => {
    if (to) {
        return (
            <ButtonStyle as={Link} to={to} target={target}>
                {children}
            </ButtonStyle>
        );
    } else {
        return <ButtonStyle>{children}</ButtonStyle>;
    }
};

Button.propTypes = {
    children: PropTypes.node,
    target: PropTypes.string,
    to: PropTypes.string
};

export default Button;
