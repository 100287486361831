import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";

import Button from "../button";
import Container from "../container";

const createMarkp = html => {
    return {
        __html: html
    };
};

const OuterStyle = styled.main`
    h1 {
        ${tw`relative light-mode:text-primary-dark`}

        em {
            ${tw`font-sans not-italic absolute`}
            font-size: 0.25em;
            margin-top: 10px;
            margin-left: -10px;

            @media screen and (min-width: 768px) {
                font-size: 0.2em;
            }
        }

        strong {
            ${tw`font-sansbold antialiased text-5xl md:text-7xl text-white light-mode:text-primary`};
            display: inline-block;
            position: relative;
            transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);

            :after {
                ${tw`absolute block bg-gray-lightest w-full bg-white light-mode:bg-primary`}
                content: "";
                margin-top: -0.5em;
                transform-origin: center left;
                animation: strikethrough 1s 0.5s cubic-bezier(0.55, 0, 0.1, 1) 1;
                transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
                height: 6px;

                @media screen and (min-width: 768px) {
                    height: 10px;
                }
            }
        }
    }

    @keyframes strikethrough {
        0% {
            transform: scaleX(0);
            opacity: 0;
        }
        5%: {
            opacity: 1;
        }
        100% {
            transform: scaleX(1);
        }
    }
`;

const InnerStyle = styled.section`
    ${tw`md:px-0 md:w-4/5`}
`;

const HtmlStyle = styled.section`
    ${tw`mb-16`}
`;

const Intro = ({ intro }) => (
    <OuterStyle>
        <Container>
            <InnerStyle>
                <HtmlStyle dangerouslySetInnerHTML={createMarkp(intro)} />
                <Button to="mailto:leonardo@giac.one">
                    Let's have a coffee
                </Button>
            </InnerStyle>
        </Container>
    </OuterStyle>
);

Intro.propTypes = {
    intro: PropTypes.string
};

export default Intro;
