/*eslint array-callback-return: "off"*/
import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import { StaticQuery, graphql } from "gatsby";
import { ParallaxProvider } from "react-scroll-parallax";

import Footer from "components/footer";
import Header from "components/header";
import Intro from "components/intro";
import Layout from "components/layout";
import Metadata from "components/metadata";

const InnerStyled = styled.div`
    ${tw`flex flex-col justify-between min-h-screen`};
`;

export default () => (
    <StaticQuery
        query={graphql`
            query IndexQuery {
                prismicHome {
                    data {
                        seo_title
                        seo_description
                        intro {
                            html
                        }
                    }
                }
            }
        `}
        render={index => (
            <ParallaxProvider>
                <Metadata
                    title={index.prismicHome.data.seo_title}
                    description={index.prismicHome.data.seo_description}
                />
                <Layout cssClass="index">
                    <InnerStyled>
                        <Header />
                        <Intro intro={index.prismicHome.data.intro.html} />
                        <Footer />
                    </InnerStyled>
                </Layout>
            </ParallaxProvider>
        )}
    />
);
